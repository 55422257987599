<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="contacts"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="contactsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Contacts</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('contact_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Contact
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Email -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedContact.email"
                              :rules="emailRules"
                              label="Email"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Email <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- First Phone number -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedContact.phone1"
                              :rules="phone1Rules"
                              label="First Phone number"
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  First Phone number
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Second Phone number -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedContact.phone2"
                              :rules="phone2Rules"
                              label="Second Phone number"
                            >
                              <template v-slot:label>
                                <p class="body-1">Second Phone number</p>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this contact?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteContactConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('contact_update')"
              small
              class="mr-2"
              @click="editContact(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('contact_delete')"
              small
              @click="deleteContact(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Contacts per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementContacts",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Email", value: "email" },
        { text: "First Phone Number", value: "phone1" },
        { text: "Second Phone Number", value: "phone2" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      contacts: [],

      editedIndex: -1,
      editedContact: {
        id: 0,
        email: "",
        phone1: "",
        phone2: "",
        created_at: "",
        updated_at: "",
      },
      defaultContact: {
        id: 0,
        email: "",
        phone1: "",
        phone2: "",
        created_at: "",
        updated_at: "",
      },

      // email
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // First Phone Number
      phone1Rules: [
        (v) => !!v || "Required",
        (v) => v.length <= 20 || "Can not be more than 20 characters",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      // Second Phone Number
      phone2Rules: [
        // (v) => !!v || "Required",
        // (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        // (v) => v.length == 12 || "Mobile phone is incorrect",
      ],

      page: 1,
      contactsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Contact" : "Edit Contact";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedContacts();
    },

    // contactsPerPage
    contactsPerPage() {
      this.getApiPagedContacts();
    },

    // perPageChoice
    perPageChoice(val) {
      this.contactsPerPage = val.value;
      this.page = 1;
    },

    // editedContact.phone1
    // "editedContact.phone1"(val) {
    //   this.editedContact.phone1 = this.$helpers.formatInputMobileNumber(val);
    // },

    // editedContact.phone2
    // "editedContact.phone2"(val) {
    //   this.editedContact.phone2 = this.$helpers.formatInputMobileNumber(val);
    // },
  },

  created() {
    if (!this.$can("contact_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // this.getApiContacts();
    this.getApiPagedContacts();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiContact();
        } else {
          this.createApiContact();
        }
      }
    },

    // editContact
    editContact(contact) {
      this.editedIndex = this.contacts.indexOf(contact);
      this.editedContact = Object.assign({}, contact);
      this.dialog = true;

      if (this.editedIndex !== -1) {
        this.$refs.form.validate();
      }
    },

    // deleteContact
    deleteContact(contact) {
      this.editedIndex = this.contacts.indexOf(contact);
      this.editedContact = Object.assign({}, contact);
      this.dialogDelete = true;
    },

    // deleteContactConfirm
    deleteContactConfirm() {
      this.deleteApiContact();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedContact = Object.assign({}, this.defaultContact);
        this.editedIndex = -1;
      });

      this.$refs.form.reset();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedContact = Object.assign({}, this.defaultContact);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiContacts
    async getApiContacts() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("contacts");

      if (res.status == 200) {
        this.contacts = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedContacts
    async getApiPagedContacts() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `contacts/paged?page_no=${this.page}&page_size=${this.contactsPerPage}`
      );

      if (res.status == 200) {
        this.contacts = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiContact
    async createApiContact() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "contacts",
        {
          email: this.editedContact.email,
          phone1: this.editedContact.phone1,
          phone2: this.editedContact.phone2,
        },
        "Contact Created Successfully!"
      );

      if (res.status == 200) {
        this.contacts.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiContact
    async updateApiContact() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `contacts/${this.editedContact.id}`,
        {
          id: this.editedContact.id,
          email: this.editedContact.email,
          phone1: this.editedContact.phone1,
          phone2: this.editedContact.phone2,
        },
        "Contact Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.contacts[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiContact
    async deleteApiContact() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `contacts/${this.editedContact.id}`,
        "Contact Deleted Successfully!"
      );

      if (res.status == 200) {
        this.contacts.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
